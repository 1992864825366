<template>
  <div>
    <bread></bread>
    <div class="center-content">
      <div class="iq-card-body flex-start">
        <div class="top1 title-t">挂账概览</div>
        <div class="top1">
          <div class="flex-start">
            <img src="@/assets/img/i_people.png">
            <div>挂账人次</div>
          </div>
          <div class="text-c">{{ orderInfo.debtPeopleCount }}</div>
        </div>
        <div class="top1">
          <div class="flex-start">
            <img src="@/assets/img/i_orderr.png">
            <div>挂账订单数</div>
          </div>
          <div class="text-c">{{ orderInfo.debtOrderCount }}</div>
        </div>
        <div class="top1">
          <div class="flex-start">
            <img src="@/assets/img/i_amount.png">
            <div>挂账金额合计（元）</div>
          </div>
          <div class="text-c"><span class="t1">￥</span>{{ orderInfo.debtMoneyTotal ? orderInfo.debtMoneyTotal.toFixed(2) :
            '0.00' }}</div>
        </div>
      </div>
    </div>
    <div class="center-b">
      <div class="iq-card-body">
        <div class="table-top">
          <div class="iq-search-bar">
            <el-form :model="searchForm" :inline="true">
              <el-form-item>
                <el-date-picker v-model="searchForm.datepicker" type="daterange" range-separator="至"
                  start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"
                  :picker-options="pickerOptions0" size="small" style="margin-right: 10px; width: 260px" clearable>
                </el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-input v-model="searchForm.keyWord" type="text" size="small" placeholder="订单号/姓名/手机号"
                  @keyup.enter.native="Search" clearable></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" size="small" @click="Search()">搜索</el-button>
                <!-- <el-button type="warning" size="small" @click="BatchPayBtn()">合并付款</el-button> -->
              </el-form-item>
            </el-form>
          </div>
        </div>
        <el-table :data="tableData" ref="multipleTable" stripe style="width: 100%"
          :header-cell-style="{ background: '#F5F7FC' }" height="calc(100% - 80px)">
          <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
          <el-table-column prop="orderNum" label="订单编号"></el-table-column>
          <el-table-column prop="debtUserName" label="姓名" align="center"> </el-table-column>
          <el-table-column prop="debtUserPhone" label="手机号" align="center"> </el-table-column>
          <el-table-column prop="paySuccessTime" label="挂账日期" align="center" width="160px" sortable>
            <template slot-scope="scope">
              {{ scope.row.paySuccessTime | timefilters }}
            </template>
          </el-table-column>
          <el-table-column prop="totalMoney" label="总金额（元）" align="center">
            <template slot-scope="scope">
              {{ scope.row.totalMoney.toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column prop="userPayMoney" label="应收金额（元）" align="center">
            <template slot-scope="scope">
              {{ (scope.row.totalMoney - scope.row.discountsMoney).toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column prop="debtMoney" label="挂账金额（元）" align="center">
            <template slot-scope="scope">
              {{ scope.row.debtMoney.toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column prop="userName" label="收费员" align="center"></el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <span class="info optionBtn" @click="toCreditInfo(scope.row)">详情</span>
              <span class="primary optionBtn" @click="toCharge(scope.row)">立即付款</span>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @current-change="changePage" :page-size="pageSize" :current-page="pageIndex" :total="dataTotal"
          layout="total, prev, pager, next, jumper"></el-pagination>
      </div>
      <el-dialog title="确认收费" :visible.sync="setDialog" width="35%">
        <el-form class="dialog-form" :model="form" ref="chargeForm" :label-width="formLabelWidth" :rules="rules">
          <el-form-item label="付款金额" prop="payMoney" style="color: #FF0000;" class="numT">
            <el-input-number v-model="form.payments[0].payMoney" size="small" controls-position="right" :precision="2"
              :step="0.1" :min="1" class="leftInput" disabled></el-input-number>
          </el-form-item>
          <el-form-item label="付款方式" prop="type">
            <el-radio-group v-model="form.payments[0].payWay">
              <el-radio :label="0">现金</el-radio>
              <el-radio :label="1">微信</el-radio>
              <el-radio :label="2">支付宝</el-radio>
              <el-radio :label="3">银行卡</el-radio>
              <el-radio :label="4">其他</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="CloseBtn('chargeForm')">取 消</el-button>
          <el-button type="primary" @click="saveCharge('chargeForm')">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { Outpatient } from "@/components/DrugDomain/Outpatient"
export default {
  data () {
    var outpatient = new Outpatient(this.TokenClient, this.Services.Drug)
    return {
      OutpatientDomain: outpatient,
      headers: { "Content-Type": "multipart/form-data;charset=UTF-8" },
      tableData: [],
      orderInfo: {},
      pageIndex: 1,
      dataTotal: 1,
      pageSize: 0,
      setDialog: false,
      form: {
        id: 0,
        payments: [{
          debtUserName: null,
          debtUserPhone: null,
          payWay: 0,
          payMoney: 0,
          totalMoney: 0
        }]
      },
      searchForm: {
        state: '',
        keyWord: '',
        datepicker: '',
      },
      pickerOptions0: {
        disabledDate (time) {
          return time.getTime() > Date.now()
        },
      },
      formLabelWidth: '100px',
      setDialog: false,
      formLabelWidth: '100px',
      charge: {
        num: 12,
        type: 1,
        payNum: 12,
        change: 0
      },
      title: "余额明细",
      DataCount: 0,
      json_fields: {
        订单编号: {
          filed: "key",
          callback: val => {
            return "NO：" + val.key
          },
        },
        当前余额: "name",
      },
      fileList: [],
      rules: {
        entityName: [{ required: true, message: '请输入药品名称', trigger: 'blur' }],
        money: [{ required: true, message: '请输入价格', trigger: 'blur' }],
        amount: [{ required: true, message: '请输入开药量', trigger: 'blur' }],
        debtUserName: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        debtUserPhone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/, message: "请输入合法手机号", trigger: "blur" }
        ],
        debtMoney: [{ required: true, message: '请输入挂账金额', trigger: 'blur' }],
        payment: [{ required: true, message: '请输入付款金额', trigger: 'blur' }],
      },
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    BatchPayBtn () {
      var _this = this
      const _selectData = _this.$refs.multipleTable.selection
      if (_selectData.length == 0) {
        _this.$message({
          type: 'error',
          message: '请选择要付款的欠款记录',
        })
        return
      }
      let debtUserPhone = _selectData[0].debtUserPhone
      for (let index = 0; index < _selectData.length; index++) {
        let item = _selectData[index]
        if (item.debtUserPhone != debtUserPhone) {
          _this.$confirm('付款人不同', '提示', {
            confirmButtonText: '确定',
            type: 'warning',
            showCancelButton: false,
          })
          return
        }
      }
    },
    CloseBtn (formName) {
      this.setDialog = false
      this.$refs[formName].resetFields()
    },
    toCharge (item) {
      var _this = this
      _this.setDialog = true
      _this.form = {
        id: item.id,
        payments: [{
          debtUserName: item.debtUserName,
          debtUserPhone: item.debtUserPhone,
          payWay: 0,
          payMoney: item.debtMoney,
          totalMoney: item.debtMoney,
        }]
      }
    },
    saveCharge (formName) {
      var _this = this
      _this.$refs[formName].validate((valid) => {
        if (valid) {
          _this.OutpatientDomain.DebtRepayment(_this.form,
            function (data) {
              _this.$message({
                message: '操作成功',
                type: 'success',
              })
              _this.getList()
              _this.setDialog = false
            },
            function (error) {
              let jsonObj = JSON.parse(error.msg)
              _this.$message({
                message: jsonObj.msg,
                type: 'error',
              })
              console.log(error)
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    toCreditInfo (item) {
      this.$router.push({
        path: './CreditInfo',
        query: {
          orderId: item.id
        }
      })
    },
    changePage (pageIndex) {
      this.pageIndex = pageIndex
      this.getList()
    },
    getList () {
      var _this = this
      var item = _this.searchForm
      if (_this.searchForm.datepicker) {
        item.startTime = _this.searchForm.datepicker[0] + " 00:00:00"
        item.endTime = _this.searchForm.datepicker[1] + " 23:59:59"
      } else {
        item.startTime = ""
        item.endTime = ""
      }
      _this.OutpatientDomain.DebtList(item.keyWord, item.startTime, item.endTime, this.pageIndex,
        function (data) {
          _this.orderInfo = data.data
          _this.tableData = data.data.mzOrderDetailList.results
          _this.pageIndex = data.data.mzOrderDetailList.pageIndex
          _this.pageSize = data.data.mzOrderDetailList.pageSize
          _this.dataTotal = data.data.mzOrderDetailList.dataTotal
        },
        function (err) {
          console.log(err)
        }
      )
    },
    Search (event) {
      this.pageIndex = 1
      this.tableData = []
      this.getList()
    },
    // 导入数据
    handleExcel (file, fileList) {
      console.log(file.raw)
      let formData = new FormData() //声明一个FormDate对象
      formData.append("formFile", file.raw) //把文件信息放入对象中

    },
    //模板下载
    downloadTemplate () {
      let a = document.createElement("a")
      a.href = "./drug_import_template_with_branch.xls"
      a.download = "药品模板.xlsx"
      a.style.display = "none"
      document.body.appendChild(a)
      a.click()
      a.remove()
    },
    async fetchData () {
      var _this = this
      return await _this.getAllData()
    },
    getAllData () {
      var _this = this
      return new Promise((resolve, reject) => {
        // var item = _this.searchForm;
        // if (_this.searchForm.datepicker) {
        //   item.startTime = _this.searchForm.datepicker[0] + " 00:00:00";
        //   item.endTime = _this.searchForm.datepicker[1] + " 23:59:59";
        // } else {
        //   item.startTime = "";
        //   item.endTime = "";
        // }
        // _this.BalanceDomain.BalanceDetail(
        //   item.startTime,
        //   item.endTime,
        //   item.keyWord,
        //   item.state,
        //   1,
        //   _this.DataCount,
        //   function (data) {
        //     resolve(data.data.results);
        //   },
        //   function (err) {
        resolve("")
        //     console.log(err);
        //   }
        // );
      })
    },
  },
}
</script>

<style scoped lang="scss">
.center-content {
  // height: 120px;

  .iq-card-body {
    height: 120px;

    .title-t {
      margin-top: -60px;
      font-weight: bold;
    }

    .top1 {
      margin-right: 10%;

      img {
        margin-right: 5px;
      }

      .text-c {
        font-size: 38px;
        font-weight: 400;
        color: #ED3E2B;
        margin-bottom: -10px;

        .t1 {
          font-size: 15px;
        }
      }
    }
  }
}

.center-b {
  padding: 20px 0 0;
  height: calc(100vh - 238px);
}

.dialog-form {
  width: 90%;

}

.dialog-form .numT ::v-deep .el-form-item__label {
  color: #FF0000;
}

.table-top {
  margin-bottom: -10px;
}
</style>
